import React, { Component } from "react";

export default class Introduction extends Component {
    render() {
        return (
            <div>
                <section
                    id="colorlib-hero"
                    className="js-fullheight"
                    data-section="home"
                >
                    <div className="flexslider js-fullheight">
                        <ul className="slides">
                            <li
                                style={{
                                    backgroundImage: "url(images/img_bg.jpg)",
                                }}
                            >
                                <div className="overlay" />
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                                            <div className="slider-text-inner js-fullheight">
                                                <div
                                                    className="desc"
                                                    style={{
                                                        backgroundColor:
                                                            "rgba(0, 0, 0, 0.3)",
                                                    }}
                                                >
                                                    <h1>
                                                        Hi! <br />
                                                        I'm Mario
                                                    </h1>
                                                    <p>
                                                        <a
                                                            className="btn btn-primary btn-learn"
                                                            href="https://www.dropbox.com/scl/fi/c3hunq6k438hejnv9beod/MarioKonja-3-26-2023.pdf?rlkey=31se414jldko4szm87wpxiisa&dl=0"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View Resume
                                                            <i className="icon-download4" />
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li
                                style={{
                                    backgroundImage: "url(images/img_bg2.jpg)",
                                }}
                            >
                                <div className="overlay" />
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                                            <div className="slider-text-inner">
                                                <div
                                                    className="desc"
                                                    style={{
                                                        backgroundColor:
                                                            "rgba(0, 0, 0, 0.3)",
                                                    }}
                                                >
                                                    <h1>
                                                        I love building
                                                        <br /> THINGS !!
                                                    </h1>
                                                    <p>
                                                        <a
                                                            className="btn btn-primary btn-learn"
                                                            href="https://github.com/K0nja"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View Projects{" "}
                                                            <i className="icon-briefcase3" />
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li
                                style={{
                                    backgroundImage: "url(images/img_bg3.jpg)",
                                }}
                            >
                                <div className="overlay" />
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                                            <div className="slider-text-inner">
                                                <div
                                                    className="desc"
                                                    style={{
                                                        backgroundColor:
                                                            "rgba(0, 0, 0, 0.3)",
                                                    }}
                                                >
                                                    <h1>
                                                        I often <br />
                                                        Stream ...{" "}
                                                    </h1>
                                                    <p>
                                                        <a
                                                            className="btn btn-primary btn-learn"
                                                            href="https://www.twitch.tv/konja_"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View Stream{" "}
                                                            <i className="icon-book" />
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        );
    }
}
